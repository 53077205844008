<template>
  <div class="container">
    <div class="banner"></div>
    <!-- <div class="goods-select-bar">
      <div class="goods-select-item "
           :class="{active:rankType == 0}"
           @click="sorttype(0)">
        综合
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item"
           :class="{active:rankType == 2}"
           @click="sorttype(2)">
        销量
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item"
           :class="{active:rankType == 3 || rankType == 4 }"
           @click="sorttype(3)">
        价格
        <span class="split-line">/</span>
      </div>
      <div class="goods-select-item">
        产地
        <span class="split-line">/</span>
      </div>
      <div class="search-tips">
        共找到 <span class="keyword">{{totalCount}}</span> 款符合条件的商品
      </div>
    </div> -->
    <!-- 商品列表 -->
    <ul class="goods-list"
        v-if="resultList.length">
      <li v-for="item in resultList"
          :key="item.id"
          @click="handleToGoods(item)">
        <!-- <RouterLink to="/"> -->
        <img v-lazy="item.pictureUrl">
        <p class="name ellipsis-2">{{ item.goodsName }}</p>
        <p class="price-bar"><span class="discount">{{item.contributionValue}}贡献值</span> <span class="org-price">&yen; {{item.price}}</span> </p>
        <!-- </RouterLink> -->
      </li>
    </ul>
    <div class="slok"
         v-else>
      <qdzEmpty state="notData" />

    </div>
    <qdzPagination v-if="resultList.length && refresh"
                   :total="totalCount"
                   :pageSize="pageSize"
                   :currentPage="currentPage"
                   @handleCheckPage="handleCheckPage" />
  </div>
</template>
<script >
import { toRefs, reactive, watch, } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getConvertGoodsInfo } from '@/api/home'
export default {
  name: 'contributionValue',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      keyword: '',
      currentPage: 1,
      totalCount: 1,
      totalPage: 1,
      pageSize: 10,
      resultList: [],
      refresh: true,
      rankType: 0,
      countyName: '',
    })

    const handleGetResultList = (data) => {
      getConvertGoodsInfo(data).then(res => {
        if (res.code === '10000') {
          // console.log(res)
          state.resultList = res.data.list
          state.currentPage = res.data.currentPage
          state.totalCount = res.data.totalCount
          state.refresh = true
        }
      })
    }
    watch(() => route.query, (value, oldVal) => {
      state.keyword = value.keyword
      // 关键词不同时 重新 初始化分页器 优化
      if (value.keyword != oldVal?.keyword) {
        state.refresh = false
      }
      // console.log('3321')
      handleGetResultList({
        keyword: value.keyword,
        showClass_id: value.id
      })

    }, { immediate: true })
    //  筛选数据  
    const sorttype = (type) => {
      if (type == 3) {
        type = state.rankType == 4 ? 3 : 4
      };

      if (state.rankType == type) return;

      state.rankType = type;
      state.currentPage = 1;
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
      })
    }
    // 切换分页
    const handleCheckPage = (currentPage) => {
      state.currentPage = currentPage
      handleGetResultList({
        keyword: route.query.keyword,
        showClass_id: route.query.id,
        type: 1,
        rankType: state.rankType,
        countyName: state.countyName,
        currentPage: currentPage
      })
    }
    // 去详情页
    const handleToGoods = (item) => {
      let toPath = router.resolve({
        path: 'goods',
        query: {
          goodsId: item.goodsId,
          val: 1
        }
      })
      window.open(toPath.href, '_blank')

    }
    return {
      ...toRefs(state),
      sorttype,
      handleCheckPage,
      handleToGoods,
    }
  }
}

</script>
<style scoped lang="less">
.banner {
  height: 485px;
  background: #efe9dc;
  background-image: url("~@/assets/images/contributionValue/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 29px;
}
.goods-select-bar {
  display: flex;
  align-items: center;
  margin: 30px 0;
  .goods-select-item {
    color: #bec3c9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    &.active {
      color: #111;
    }
  }
  .split-line {
    margin: 0 19px;
    color: #bec3c9;
  }
}
.search-tips {
  font-size: 12px;
  font-weight: 400;
  color: #9499a3;
}
.keyword {
  color: #d6403b;
}
.slok {
  height: 1000px;
}
.goods-list {
  display: flex;
  flex-wrap: wrap;
  height: 686px;
  li {
    width: 240px;
    height: 333px;
    background: #fff;
    margin-bottom: 10px;
    margin-right: 10px;
    // transition: none !important;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .hoverShadow();
    img {
      width: 240px;
      height: 217px;

      padding: 25px 19px;
    }
    .name {
      width: 209px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      margin: 14px auto;
      font-size: 14px;
      font-family: PingFang TC-Regular, PingFang TC;
      font-weight: 400;
      color: #5f6674;
    }

    .price-bar {
      padding: 0 14px;
      color: @priceColor;
      .org-price {
        font-size: 12px;
        font-weight: 400;
        color: #dfe0e3;
        margin-left: 10px;
      }
    }
  }
}
</style>
